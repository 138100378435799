@keyframes wave {
    0% {transform: rotate(0deg)}
    10% {transform: rotate(-15deg)}
    20% {transform: rotate(15deg)}
    30% {transform: rotate(-15deg)}
    40% {transform: rotate(0deg)}
  }

  #about {
      overflow: hidden;
  }
.animatedWave {
    display: inline-block;
    animation-iteration-count: infinite; 
    animation-name: wave;
    animation-duration: 3s;
    font-weight: 100;
}


.outerContainer {
    display:flex;
    flex-direction:row;
    width:100vw;
    height:100vh;
    overflow: hidden;
}

.outerContainer .textContainer {
    display:flex;
    flex-direction:column;
    flex:0.5;
    height:100%; 
    align-items:flex-start;
    padding-left:15%;
    justify-content:center, 
}

.outerContainer .emojiContainer {
    display:flex; 
    flex-direction:column; 
    flex:0.5; 
    height:100%; 
    align-items:flex-start; 
    justify-content:center;
}

.textContainer .title {
    color:var(--text);
    font-size: 4vw; 
    font-family:edition;
    margin:0;
}

.textContainer .subtitle {
    color:var(--text);
    font-size:1.3vw;
    font-family:Poppins; 
    font-weight:400; 
    width:80%;
}

.textContainer .content {
    color:var(--text); 
    font-size:1.1vw; 
    font-family:Poppins; 
    font-weight:300; 
    width:80%; 
    margin-top:-0.5vw; 
}

.textContainer .content a {
    color:var(--link) !important; 
}

.emojiContainer .emoji {
    width:40vw;
    height:40vw;
    margin-left:-5vw;
}

@media only screen and (max-width: 1200px) {
    
    .outerContainer .textContainer {
        // margin-top:10%;
        flex:0.6;
        justify-content: center;
        align-items:flex-start; 
        padding-left: 10%;
    }
    
    .outerContainer .emojiContainer {
        align-items:center; 
        justify-content:center;
    }
    
    .textContainer .title {
        font-size:2.5em; 
        margin:0;
    }
    
    .textContainer .subtitle {
        font-size:1.3em;
        width:85%;
        font-weight: 500;
        margin:0.5em 0;
    }
    
    .textContainer .content {
        font-size:0.8em; 
        width:85%; 
        margin-top:-0.3em; 
    }
    .emojiContainer .emoji {
        width:50vh;
        height:50vh;
        margin-top: 10% !important;   
}
} 


@media only screen and (max-width: 768px) { 
    
    .outerContainer {
        flex-direction:column;
        position: absolute;
        height:100vh;
    }

.outerContainer .textContainer {
    justify-content: flex-start;
    margin-top:15% !important;
    flex: 0.4;
}

.textContainer .title {
    font-size:2.2em; 
}

.textContainer .subtitle {
    font-size:1.4em;
}

.textContainer .content {
    font-size:0.95em; 
}
.emojiContainer {
    flex:0.4;
}
.emojiContainer .emoji {
    width:33vh;
    height:33vh;
    margin-top: -5vh !important;
}
}

@media only screen and (max-width: 700px) { 
    
    .outerContainer {
        flex-direction:column;
        position: absolute;
        height:100vh;
    }

.outerContainer .textContainer {
    justify-content: flex-start;
    margin-top:22% !important;
    flex: 0.4;
}

.textContainer .title {
    font-size:1.6em; 
}

.textContainer .subtitle {
    font-size:0.8em;
}

.textContainer .content {
    font-size:0.72em; 
}
.emojiContainer {
    flex:0.4;
}
.emojiContainer .emoji {
    width:33vh;
    height:33vh;
    margin-top: -5vh !important;
}
}

@media only screen and (max-width: 650px) { 
    
    .outerContainer .textContainer {
        justify-content: flex-start;
        margin-top:20%;
        flex: 0.4;
    }
    
    .textContainer .title {
        font-size:1.4em; 
    }
    
    .textContainer .subtitle {
        font-size:0.7em;
    }
    
    .textContainer .content {
        font-size:0.65em; 
    }
    .emojiContainer {
        flex:0.4;
    }
    .emojiContainer .emoji {
        margin-top: -5vh;
    }
}
