
 @font-face {
	font-family: 'edition';
	src: url('../assets/Edition-BxnV.ttf'); /* IE9 Compat Modes */
} 
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');
// MonumentExtended-Ultrabold.otf
// MonumentExtended-Regular.otf
// Edition-BxnV.ttf
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@700&&display=swap');

.aboutPage {
	height: 100%;
	overflow: hidden;
}
  .strike {
	position: relative;
  }
  .strike::after {
	content: ' ';
	position: absolute;
	top: calc(50% - 0.15vw);
	left: -0.5vw;
	width: 0;
	height: 0.3vw;
	background: var(--text);
	transition: 0.5s ease-in-out;
  }

  .activeStrike::after {
	width: calc(100% + 0.8vw);
}

#scrollDownContainer {
	position: absolute;
	bottom: 5vh;
	color:#607878;
	font-size: 2vw;
	animation: bounce 2s ease-in-out 3s infinite;
}

@keyframes bounce {
	0%,
	100%,
	20%,
	50%,
	80% {
	  -webkit-transform: translateY(0);
	  -ms-transform: translateY(0);
	  transform: translateY(0);
	}
	40% {
	  -webkit-transform: translateY(-10px);
	  -ms-transform: translateY(-10px);
	  transform: translateY(-10px);
	}
	60% {
	  -webkit-transform: translateY(-5px);
	  -ms-transform: translateY(-5px);
	  transform: translateY(-5px);
	}
  }
  



html, body {
	scroll-behavior: smooth;
	cursor: none;
}

  a {
    cursor: pointer;
}

.loading {
	// background-color:red;
	height: 100vh;
	width: 100vw;
	padding: 0;
	margin:0;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	z-index: 200;
	transition: 1s ease-in-out;
  }

  .loadingDone1 {
	opacity: 0;
}

  .loadingDone2 {
	display: none;
  }
  .loading img{
	color: var(--text);
	width: 20vw;
	height: 20vw;
  }

  .websiteContainer {
	width:100vw;
	height:100vh;
	overflow:hidden; 
	position:relative;
	display: flex;
	justify-content: center;
	align-items: center;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Poppins:wght@200;300;400;500;600;700;800&display=swap');


.ball1 {
    background-position:center;
        background-size: cover;background-image: url('../assets/item1.png');width:15.6vw;height:15.6vw;
        
}
.ball2 {
    width:10.4vw;height:10.4vw;background-position:center;
        background-size: cover;background-image: url('../assets/item2.png');
        

}

.ball3 {
    background-position:center;
        background-size: cover;background-image: url('../assets/item3.png');width:26vw;height:26vw;
        
}

.ball4 {
    background-position:center;
    background-size: cover;background-image: url('../assets/item4.png');width:14vw;height:14vw;
    
}

.ball5 {
    background-position:center;
        background-size: cover;background-image: url('../assets/item5.png');width:5.2vw;height:5.2vw;
        
}
.ball6 {
    background-position:center;
        background-size: cover;background-image: url('../assets/item4.png');width:5.2vw;height:5.2vw;
        
}

.container{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	display: flex;
	align-items: center;
    justify-content: center;
	z-index: 3;
    
}

.container2{
    position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	display: flex;
	align-items: center;
    justify-content: center;
    z-index: 2;
}

.container3{
    position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	display: flex;
	align-items: center;
    justify-content: center;
    z-index: 4;
}

.breh {
	text-align: left !important;
}

.container h1 {
	color: var(--name-text);
	line-height: 0;
	font-size: calc(1em + 11vw) ;  
    font-weight: 800;
    justify-self: center;
    align-self: center;
	font-family: 'edition', sans-serif;
	letter-spacing: -0.02em;
	text-shadow: 
	0.035em 0.035em 0 mix(#3f3f3f, #354747, 32%),
	0.03em 0.03em 0 mix(#3f3f3f, #354747, 30%),
	0.025em 0.025em 0 mix(#3f3f3f, #354747, 28%),
	0.02em 0.02em 0 mix(#3f3f3f, #354747, 26%), 
	0.015em 0.015em mix(#3f3f3f, #354747, 24%), 
	0.01em 0.01em 0 mix(#3f3f3f, #354747, 22%), 
	0.005em 0.005em 0 mix(#3f3f3f, #354747, 20%),
	-0.0075em 0.0075em 0 mix(#3f3f3f, #354747, 54%);


}

.container h2 {
	color: var(--name-text);
	line-height: 2vw;
	margin-left: 0.5vw;
	font-size: calc(1em + 5vw) ;  
    font-weight: 800;
    justify-self: center;
    align-self: center;
	font-family: 'edition', sans-serif;
	text-shadow: 
	0.06em 0.06em 0 mix(#3f3f3f, #354747, 32%),
	0.055em 0.055em 0 mix(#3f3f3f, #354747, 30%),
	0.05em 0.05em 0 mix(#3f3f3f, #354747, 28%),
	0.045em 0.045em 0 mix(#3f3f3f, #354747, 26%), 
	0.04em 0.04em mix(#3f3f3f, #354747, 24%), 
	0.035em 0.035em 0 mix(#3f3f3f, #354747, 22%), 
	0.03em 0.03em 0 mix(#3f3f3f, #354747, 20%),
	-0.0175em 0.0175em 0 mix(#3f3f3f, #354747, 54%);


}

.socials {
	position: fixed;
	justify-self: center;
	left: 0;
	z-index: 20;
}

.socials ul {
	list-style: none;
	list-style-type: none;
}

.socials ul li {
	cursor: pointer;
	margin-bottom: 0.5vw;
}

.socials a {
	color: var(--text);
	font-size: 2.2vw;
}

.links {
	position: fixed;
	bottom:4vh;
	right:2vw;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	justify-content: space-between;
	flex-direction: column;
	z-index: 20;
}

.links button {
	background:none;
	border: none;
	cursor: pointer;
	font-family: 'edition', sans-serif;
	font-weight: 700;
	color:var(--text);
	margin:0.3vw 0;
	text-decoration: none;
	font-size: 1.6vw;
	display: block;
	text-align: left !important;
	// text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.425);
}

.mobileMenu {
	position:fixed;
	top:5vh;
	right:8vw;
	z-index:30;
	display: none;
}

.menu {
	position: fixed;
	z-index: 25;
	width: 0vw;
	height: 100vh;
	background-color: var(--blackboard);
	top:0;
	right: 0;
	display: none;
	transition:0.6s ease-in-out;
}

.menuActive {
	width: 50vw;
}

.menu ul {
	list-style: none;
	list-style-type: none;
	margin-top: 15vw;
	margin-left: 5vw;
}
.menu ul li {
	cursor: pointer;
	margin-bottom: 1.3vw;
}

.menu ul li button, .menu ul li a {
	background: none;
	border: none;
	cursor: pointer;
	font-family: 'edition', sans-serif;
	font-weight: 700;
	color:var(--blackboard-text);
	text-decoration: none;
	font-size: 1.7em;
	padding-top: 0;
	display: block;
	text-align:left;
	transition: 0.2s ease-out;
}

.menu ul li button:hover, .menu ul li a:hover {
	color:var(--link) !important;
}

.mobileContact {
	margin-left: 0.13em;
}

.appContainer{
	width:100vw;
	height:100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ContactContainer {
	position: fixed;
	margin:0;
	background-color: var(--text);
	z-index: 100;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 0;
	width: 0;
	transition: 0.4s ease-out;
	pointer-events: none;
	opacity: 0;
	width: 100vw;
	height: 100vh;
	border-radius: 5px;
}
  
.ContactContainerOpen {
	pointer-events: all;
	opacity: 100;
	width: calc(100vw - 4vw);
	height: calc(100vh - 4vw);
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.contactInner {
	width: 100%;
	height: 100%;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	top:0;
	left:0;
	opacity: 0;
	transition: 0.25s ease-out;
}

.ContactContainerOpen .contactInner {
	opacity: 1;
	transition: 1.1s ease-in-out;
	transition-delay: 0.7s;
}

.ContactContainer .diagonalBackground {
	background-color: #435252;
	width:200vw;
	height: 100vh;
	position: absolute;
	top:60%;
	transform: rotateZ(-15deg);
	left: -50%;
	z-index: 1;
}

.ContactContainer h3{
	font-family: 'edition', sans-serif;
	font-weight: 500;
	color:var(--blackboard-text);
	font-size: 1.1vw;
	line-height: 0;
	z-index: 2;
	margin-bottom: -1vw;
}

.ContactContainer h1{
	font-family: 'edition', sans-serif;
	font-weight: 600;
	color:var(--blackboard-text);
	font-size: 5vw;
	line-height: 5vw;
	letter-spacing: -0.25vw;
	z-index: 2;
	transition: 0.2s ease-in-out;
	position: relative;
	text-align: center;
}

.ContactContainer h1 a{ 
	text-decoration: none;
	color:inherit;
}

.ContactContainer h1 ::before {
	content: "";
	position: absolute;
	width: 100%;
	height: 0.2vw;
	bottom: 0;
	left: 0;
	background-color: var(--blackboard-text);
	visibility: hidden;
	transform: scaleX(0);
	transition: all 0.3s ease-in-out;
  }

  .ContactContainer h1 :hover::before {
	visibility: visible;
	transform: scaleX(1);
	background-color: var(--link);
  }

.ContactContainer h1:hover{
	color: var(--link);
}

.noFont {
	font-family: 'Montserrat', sans-serif;
}

.ContactContainer .contactMenu {
	position: absolute;
	top:3vw;
	right: 3vw;
}

.ContactContainer .contactMenu *{
	cursor: pointer;
}

.ContactContainer .contactSocials {
	position: absolute;
	bottom:5vw;
	display: flex;
	flex-direction: row;
	z-index: 3;
	flex-wrap: wrap;
	justify-content: center;
}

.ContactContainer .contactSocials h2{
	cursor: pointer;
	color:var(--blackboard-text);
	font-size: 2vw;
	margin:0 0.5vw;
	transition: 0.2s ease-in-out;
}

.ContactContainer .contactSocials h2 a{
	color:inherit;
}


.ContactContainer .contactSocials h2:hover{
	color: var(--link);
}
.ContactContainer .contactSocials h2:hover ~ #currentSocial{
	opacity: 1;
	color: var(--link);
}

#currentSocial {
	transition: 0.2s ease-in-out;
	opacity: 0;
}
.ContactContainer .contactSocials .break {
	width: 100%;
	height: 0;
}

.mobileSocials {
	position: relative;
	flex-direction: row;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.mobileSocials h2 a{
	color:var(--blackboard-text);
	font-size: 1.6em !important;
	margin-right: 1em;
}

@media only screen and (max-width: 1366px) {
	.socials {
		display: none;
	}
	.breh {
		margin-top: -10vw;
	}
	
	.container h1 {
		font-size: calc(1em + 13vw) ;  
	}
	
	.container h2 {
		margin-top: 2vw;
		font-size: calc(1em + 5vw) ;  
	}
	.links a {
		font-size: 1.8vw;
	}
	.ContactContainer .contactSocials h2{
		font-size: 3vw;
		margin:0 1vw;
	}
  }
  @media only screen and (max-width: 1365px) {
	.mobileMenu {
		display: block;
	}
	.menu{
		display: flex;
	}
	.links {
		display: none;
	}
	#scrollDownContainer {
		bottom:10vh;
		font-size: 2.5em;
	}
	.ContactContainer {
		display: none !important;
	}
  }
  

  @media only screen and (max-width: 991px) {
	.mobileMenu {
		top:5vh;
		right:6vw;
	}
	#scrollDownContainer {
		bottom: 8vh;
		font-size: 2.8em;
	}
	.ball1 {
		width:20vw;height:20vw;border-radius:20vw;		
	}
	.ball2 {
		width:16vw;height:16vw;border-radius:20vw;
	}
	
	.ball3 {
		width:35vw;height:35vw;border-radius:40vw;	
	}
	
	.ball4 {
		width:19vw;height:19vw;border-radius:20vw;	
	}
	
	.ball5 {
		width:8vw;height:8vw;border-radius:20vw;	
	}
	.ball6 {
		width:8vw;height:8vw;border-radius:20vw;		
	}
	.menuActive {
		width: 100vw;
	}
	.menu ul {
		margin-top: 8vh;
		margin-left: 5vw;
	}
	.menu ul li {
		margin-bottom: 2.5vw;
	}
  }

  @media only screen and (max-width: 767px) {
	.container h1 {
		font-size: calc(1em + 11vw) ;  
	}
	
	.container h2 {
		margin-top: 7vh;
		font-size: calc(1em + 5vw) ;  
	}
	.mobileMenu {
		top:5vh;
		right:8vw;
	}
	.ball1 {
		width:30vw;height:30vw;border-radius:30vw;		
	}
	.ball2 {
		width:20vw;height:20vw;border-radius:30vw;
	}
	
	.ball3 {
		width:50vw;height:50vw;border-radius:60vw;
	}
	
	.ball4 {
		width:28vw;height:28vw;border-radius:30vw;	
	}
	
	.ball5 {
		width:12vw;height:12vw;border-radius:30vw;	
	}
	.ball6 {
		width:12vw;height:12vw;border-radius:30vw;		
	}
	.appContainer {
		width:100vw;
		height:100vh;
		overflow-x:hidden; 
		overflow-y: visible;
		position:relative;
	}
  }