@font-face {
	font-family: 'edition';
	src: url('../assets/Edition-BxnV.ttf'); /* IE9 Compat Modes */
} 

.currentWorkActive {
	color:var(--text) !important;
	opacity: 1 !important;
}

.appContainer {
	flex-direction:row;
}


.flexContainer1 {
	flex:0.45;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.flexContainer2 {
	flex: 0.6;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}


.works h1 {
	font-size: 3.2vw;
    font-weight: 800;
    justify-self: center;
	align-self: center;
	line-height: 0.9;
	font-family: 'edition', sans-serif;
	letter-spacing: -0.02vw;
	transition: 0.3s ease-in-out;
	color: var(--text);
}

.worksActives h1{
	opacity: 0.5;
}


.works:hover h1{
	opacity: 0.5;
}

.works h1:hover{
	color:var(--text);
	opacity: 1;
}

.description {
	width: 80%;
	margin-left: 5%;
}

.description .Tilt {
	transform-style: preserve-3d !important;
}

.description #black-board {
	background-color: var(--blackboard);
	border-radius: 0.5vw;
	box-shadow: 0.1vw 0.1vw 0 0.2vw var(--blackboard-shadow), 
	0.2vw 0.2vw 0 0.2vw var(--blackboard-shadow), 
	0.3vw 0.3vw 0 0.2vw var(--blackboard-shadow), 
	0.4vw 0.4vw 0 0.2vw var(--blackboard-shadow), 
	0.5vw 0.5vw 0 0.2vw var(--blackboard-shadow),
	0.6vw 0.6vw 0 0.2vw var(--blackboard-shadow),
	0.7vw 0.7vw 0 0.2vw var(--blackboard-shadow);
	padding:1vw 3vw;
	display: flex;
	align-items: center;
	justify-content: center;
	height: auto;
	transition: 2s cubic-bezier(.78,-0.31,.3,1.29);
	// transition: 2s cubic-bezier(0.77, 0, 0.175, 1);
	transform-style: preserve-3d !important;
	transform:rotateY(0);
	width: 80%;
}

.description #black-board #textContainer {
	transform: translateZ(5vw) !important;
	transition: 1s ease-out;
}


.description h2 {
	font-size: 2vw;
	padding-top: 1vw;
	color: var(--blackboard-text); 
	font-family: 'edition', sans-serif;
}

.description p {
	font-size: 1vw;
	color: var(--blackboard-text); 
	font-family: 'poppins', sans-serif;
}

.description a {
	text-decoration: none;
	font-size: 0.9vw;
	color: var(--link); 
	font-family: 'poppins', sans-serif;
	border:0.2vw solid var(--link);
	padding: 0.4vw 0.7vw;
	display: flex;
	width:fit-content;
	align-items: center;
	margin:1.3vw 0 1vw 0;
	position: relative;
}

.description a .background-anim {
	background: var(--link);
	width: 0;
	height: 100%;
	position: absolute;
	top:0;
	left:0;
	transition: 0.28s ease-out;
	z-index: -1; 
	opacity: 0.2;
}

.description a:hover .background-anim {
	width: 100%;
}

.description .stats {
	display: flex;
	flex-direction: row;
}

.description .stats div{
	margin-right: 10%;
}

.description .stats h4{
	font-size: 1vw;
	color: var(--blackboard-text); 
	font-family: 'edition', sans-serif;
	line-height: 0;
}

.description .stats h6{
	font-size: 0.9vw;
	color: var(--blackboard-text); 
	font-family: 'poppins', sans-serif;
	font-weight: 300;
	margin-top: 0;
	line-height: 1.5;
}

.mobileWorkMenu {
	position: fixed;
	width: 100vw;
	overflow: hidden;
	height: 0;
	background-color: var(--blackboard);
	z-index: 1000;
	transition: 1s ease-in-out;
	display: none;
}

.mobileWorkMenuActive {
	height: 100vh;
}

.mobileWorkMenu .mobileWorkMenuInner {
	position: absolute;
	top:5vh;
	left: 8vw;
	color: var(--blackboard-text);
	font-size: 2em;
}

.mobileWorkMenuContent {
	padding:10vh 8vw 0 8vw;
}

.mobileWorkMenuContent h2 {
	font-size: 1.5em;
	color: var(--blackboard-text); 
	font-family: 'edition', sans-serif;
}

.mobileWorkMenuContent p {
	font-size: 0.9em;
	color: var(--blackboard-text); 
	font-family: 'poppins', sans-serif;
}
.mobileWorkMenuContent a {
	text-decoration: none;
	font-size: 1em;
	color: var(--link); 
	font-family: 'poppins', sans-serif;
	border:0.15em solid var(--link);
	padding: 0.5em 1em;
	display: flex;
	width:fit-content;
	align-items: center;
	margin:2em 0 1vw 0;
}

.mobileWorkMenuContent .stats {
	margin-top: 2em;
	display: flex;
	flex-direction: row;
}

.mobileWorkMenuContent .stats div{
	margin-right: 10%;
}

.mobileWorkMenuContent .stats h4{
	font-size: 0.9em;
	color: var(--blackboard-text); 
	font-family: 'edition', sans-serif;
	line-height: 0;
}

.mobileWorkMenuContent .stats h6{
	font-size: 0.9em;
	color: var(--blackboard-text); 
	font-family: 'poppins', sans-serif;
	font-weight: 300;
	margin-top: 0;
	line-height: 1.5;
}




@media only screen and (max-width: 1365px) {
	.mobileWorkMenu {
		display: block;
	}

	.flexContainer1 {
		flex:1;
	}
	
	.flexContainer2 {
		display: none;
	}
	.works h1 {
		font-size: 6.5vh;
	}
	.description {
		display: none;
	}
	
}

@media only screen and (max-width: 767px) {
	.works h1 {
		font-size: calc(1em + 3.3vh);
	}
}